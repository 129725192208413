import React, { useEffect, useState } from "react"

import { ChatList } from "./chat-list"
import ChatTopbar from "./chat-topbar"
import { useAddMessage } from "@/api_store/mutations/supports/useAddMessage"
import { ToastAction } from "../ui/toast"
import { useToast } from "../ui/use-toast"
import { Message, UserData } from "@/app/admin/inbox/data"
import { BsInfoCircleFill } from "react-icons/bs"
import { useTheme } from "next-themes"
import { Button } from "../ui/button"
import { useAddNewMessage } from "@/api_store/mutations/supports/useAddNewMessage"
import { getLastItems } from "./chat-layout"

interface ChatProps {
  messages?: any
  selectedUser: UserData
  isMobile: boolean
  mySide: string
  refetchMessages: () => void
  conversationId: string
  setSendMessageRef: (sendMessage: (message: Message) => void) => void;
  refetchConv: () => void
  isMessageDataPending: boolean
  isConversations: boolean
  newMessageSender: string
  newMessageRecipient: string
  setNewMessageSender: (sender: string) => void
  setNewMessageRecipient: (recipient: string) => void
  setIsNewMessage: (b: boolean) => void
  setConversationId: (conversationId: string) => void
  handleTradeFromMessage: ((sTradeId: string) => void) | null;
  handleSeenEvent: () => void
  setUserMessages: any
  isMyConversation: boolean
  myMessages: any
  selectedAssignee: any
}

export function Chat({
  messages,
  selectedUser,
  isMobile, mySide,
  refetchMessages,
  conversationId,
  setSendMessageRef,
  isConversations,
  refetchConv,
  isMessageDataPending,
  newMessageSender,
  newMessageRecipient,
  setNewMessageSender,
  setNewMessageRecipient,
  setIsNewMessage,
  setConversationId,
  handleTradeFromMessage,
  handleSeenEvent,
  setUserMessages,
  isMyConversation,
  myMessages,
  selectedAssignee
}: ChatProps) {
  const [messagesState, setMessages] = React.useState<any[]>(messages ?? [])
  const { toast } = useToast()
  const { theme } = useTheme()

  useEffect(() => {
    setMessages(messages);
  }, [messages])

  useEffect(() => {
    const msgs = messagesState?.filter((e: any) => e.isMySide === true && e.sender !== "Distribution_logger");
    // console.log("Messages state", msgs);
    const lastUserMsgs = getLastItems(msgs);

    setUserMessages(lastUserMsgs);
  }, [messagesState, setUserMessages])

  const { mutate, isPending: isLoadingMessage } = useAddMessage({
    onSuccess: (data) => {
      if (data.message === "Message sent successfully") {
        refetchMessages()
        refetchConv()
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to sent message!",
      })
    },
  })

  const { mutate: mutateNewMessage, isPending: isLoadingNewMessage } = useAddNewMessage({
    onSuccess: (data) => {
      if (data.message === "Message sent successfully") {
        const convId = data?.data?.inboxChunkSupport?.conversationId;
        refetchMessages()
        refetchConv();
        setNewMessageRecipient("")
        setNewMessageSender("")
        setConversationId(convId);
        setIsNewMessage(false)
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to create new message!",
      })
    },
  })

  const sendMessage = (newMessage: Message) => {
    // setMessages((prevMessages: any) => [...prevMessages, newMessage]);
    // console.log(conversationId)
    const attachments = newMessage?.attachments ?? [];
    const attachImages = newMessage?.attachImages ?? [];

    // Combine the attachments and attachImages arrays
    const filesToSend = [
      ...attachments, // Attachments as File[]
      ...attachImages, // AttachImages as File[]
    ];

    const messageBody = {
      conversationId: conversationId,
      content: newMessage.message,
      media: filesToSend, // Send actual file objects here
      contextId: newMessage.contextId,
      ManualType: newMessage.isTemplate ? "template" : ""
    }
    // console.log("newMessageSender", newMessageSender, "newMessageRecipient", newMessageRecipient)
    // Call the mutation function
    if (newMessageSender?.length > 0 && newMessageRecipient?.length > 0) {
      mutateNewMessage({
        content: newMessage.message,
        media: filesToSend, // Send actual file objects here
        senderId: newMessageSender,
        recipientId: newMessageRecipient,
        ManualType: newMessage.isTemplate ? "template" : "",
      });
    }
    else {
      mutate(messageBody);
    }
  }

  useEffect(() => {
    setSendMessageRef(sendMessage);
  }, [setSendMessageRef]);

  return (
    <div className="flex flex-col justify-between w-full h-full relative text-sm">
      {
        !isConversations ?
          <div className="w-full h-full flex justify-center items-center">
            <div
              className={`absolute inset-0 z-0 bg-[url('/images/bg-pattern-3.png')] bg-top bg-repeat ${theme === "dark" ? "opacity-30" : "opacity-60"
                }`}
            ></div>
            <div className="z-10 flex flex-col items-center justify-center">
              <BsInfoCircleFill className="w-16 h-16" />
              <h1 className="mt-2 text-center text-xl">Welcome to Dream Emirates Support</h1>
              <p className="mt-2 text-center text-sm text-muted-foreground">Click on a conversation to start</p>
              <Button variant="outline" size="lg" className="mt-4 cursor-pointer" onClick={() => refetchConv()}>Get Started</Button>
            </div>
          </div> :
          <>
            <ChatTopbar
              selectedUser={selectedUser}
              conversationId={conversationId}
              refetchConv={refetchConv}
              sendMessage={sendMessage}
              vendorId={mySide}
              handleSeenEvent={handleSeenEvent}
              setConversationId={setConversationId}
              isMyConversation={isMyConversation}
              selectedAssignee={selectedAssignee}
            />

            <ChatList
              messages={messagesState}
              setMessages={setMessages}
              selectedUser={selectedUser}
              sendMessage={sendMessage}
              isMobile={isMobile}
              mySide={mySide}
              conversationId={conversationId}
              isMessageDataPending={isMessageDataPending}
              handleTradeFromMessage={handleTradeFromMessage}
              refetchConv={refetchConv}
              handleSeenEvent={handleSeenEvent}
              myMessages={myMessages}
              isMyConversation={isMyConversation}
            />
          </>
      }
    </div>
  )
}
