import { getToken } from "@/auth/config/keycloak";
import { useQuery } from "@tanstack/react-query";

export const GetUsersBySync = ({ syncUser }: any) => {
  const token = getToken();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['usersSync', syncUser],
    queryFn: async () => {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_USER_URL}/sync`, {
        cache: 'no-store',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if (!res.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await res.json();
      return data;
    },
    // enabled: !!syncUser,
  });

  return {
    data,
    isLoading,
    error,
    refetch
  };
};