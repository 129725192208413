"use client"

import React, { useCallback, useEffect, useState } from "react"
import { BiMessageX } from "react-icons/bi"
import { BsInfoCircleFill } from "react-icons/bs"
import { useSidebarInbox } from "@/hooks/use-sidebar-inbox"
import { useStore } from "@/hooks/use-store"
import DynamicDialog from "../custom/dynamic-dialog"
import { Button } from "../ui/button"
import { useCloseConversation } from "@/api_store/mutations/supports/closeConversation"
import { useToast } from "../ui/use-toast"
import CustomAvatar from "./chat-custom/custom-avatar"
import { CgTemplate } from "react-icons/cg";
import { TemplateList } from "../custom/template-list"
import { useGetTemplatesByVendorId } from "@/api_store/queries/intyegration/getTemplatesByVendorId"
import { Message } from "@/app/user/inbox/data"
import { BiTransferAlt } from "react-icons/bi";
import { TransferList } from "../custom/transfer-list"
import { useTransferConversation } from "@/api_store/mutations/supports/transferConversation"
import { getToken } from "@/auth/config/keycloak"
import { buildFSStr, FSDataType } from "@/utils/filter-sort-param"
import { GetOnlineUsersByLength } from "@/api_store/queries/user/onlineUserQuery"
import { set } from "date-fns"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { Loader2 } from "lucide-react"
import { GetSupportRoleByBusiness } from "@/api_store/queries/manager/getSupportRoleByBusiness"

interface ChatTopbarProps {
  selectedUser: any
  conversationId: string
  refetchConv: () => void
  sendMessage: (message: Message) => void
  vendorId: string
  handleSeenEvent: () => void
  setConversationId: (event: string) => void
  isMyConversation: boolean
  selectedAssignee: any
}

export default function ChatTopbar({
  selectedUser,
  conversationId,
  refetchConv,
  sendMessage,
  vendorId,
  handleSeenEvent,
  setConversationId,
  isMyConversation,
  selectedAssignee
}: ChatTopbarProps) {
  const sidebar = useStore(useSidebarInbox, (state) => state)
  const [openDelete, setOpenDelete] = useState(false)
  const [openTemplates, setOpenTemplates] = useState(false)
  const [templates, setTemplates] = useState([])
  const [transferList, setTransferList] = useState([])
  const [openTransfer, setOpenTransfer] = useState(false)
  const [fsStr, setFsStr] = useState<any>("")
  const [userOptions, setUserOptions] = useState([])
  const [userSearchText, setUserSearchText] = useState("")
  const [role, setRole] = useState("");

  const { toast } = useToast();
  const { user }: any = useKeycloak();

  const length = 100
  const page = 0

  const { data, isPending: isLoadingConversations, refetch } = useGetTemplatesByVendorId(vendorId, conversationId);

  useEffect(() => {
    if (data) {
      const tempData = data?.data?.templateNames;
      const excludeItems = ["hello_world", "sltp_triggered_message_en", "pending_trade_triggered_message_en"];
      const filteredData = tempData?.filter((item: any) => !excludeItems.includes(item));

      if (filteredData && filteredData.length > 0) {
        const formatData = filteredData?.map((item: any, index: number) => ({
          id: index + 1,
          name: item
        }))
        setTemplates(formatData);
      }
      else {
        setTemplates([]);
      }
    }
    else {
      setTemplates([]);
    }
  }, [data, conversationId]);

  const { data: supportRole } = GetSupportRoleByBusiness(vendorId, user?.userId);

  useEffect(() => {
    if (supportRole?.message === "Manager role found succesfully") {
      setRole(supportRole?.data?.result);
    }
  }, [supportRole]);

  // Fetch user data using GetOnlineUsersByLength
  const {
    data: userData,
    isLoading,
    error,
    refetch: refetchUsers,
  } = GetOnlineUsersByLength({ length, page, fsStr, dependency: openTransfer, businessId: vendorId });

  // Define transformUserDataForOptions outside of useEffect
  const transformUserDataForOptions = useCallback(() => {
    return userData?.filter((item: any) => item?.userId !== selectedAssignee)?.map((user: any) => ({
      id: user.userId,
      name: user?.userDetails?.firstName?.length > 0
        ? `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
        : user?.userDetails?.username,
      subtitle: user?.userDetails?.phone,
      avatarUrl: user?.userDetails?.attributes?.profile_photo?.[0],
    }));
  }, [userData]);

  // UseEffect to update userOptions if userData changes
  useEffect(() => {
    if (userData?.length > 0) {
      const transformedData = transformUserDataForOptions();
      // Only update userOptions if the transformed data has changed
      if (JSON.stringify(userOptions) !== JSON.stringify(transformedData)) {
        setUserOptions(transformedData);
        setTransferList(transformedData);
      }
    }
  }, [userData]);

  const handleSearchUser = (value: any) => {
    setUserSearchText(value);
    if (value.length <= 0) {
      setUserOptions(transferList);
      return;
    }
    const formattedData = userOptions?.filter((item: any) => item?.name.toLowerCase().includes(value.toLowerCase()) || item?.subtitle.toLowerCase().includes(value.toLowerCase()));
    setUserOptions(formattedData);
  }

  const closeDelete = () => {
    setOpenDelete(false)
  }
  const closeTemplates = () => {
    setOpenTemplates(false)
  }
  const closeTransfer = () => {
    setOpenTransfer(false)
  }

  const { mutate, isPending: isClosingMessage } = useCloseConversation({
    onSuccess: (data) => {
      if (data.message === "Conversation closed successfully") {
        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Conversation closed successfully!",
        })
        // refetchMessages()
        closeDelete();
        refetchConv();
        setConversationId("");
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to close message!",
      })
    },
  })

  const { mutate: transferConv, isPending: isTransfering } = useTransferConversation({
    onSuccess: (data) => {
      if (data) {
        console.log("data", data);
        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Conversation transfered successfully!",
        })

        closeTransfer();
        refetchConv();
        setConversationId("");
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to transfer conversation!",
      })
    },
  })

  const handleCloseMesage = () => {
    mutate({
      conversationId: conversationId
    })
  }

  const handleTransferMesage = (userId: string) => {
    transferConv({
      conversationId,
      userId
    })
  }

  const handleSend = (message: string) => {
    if (isMyConversation) {
      handleSeenEvent();
    }
    if (message.trim()) {
      const newMessage: Message = {
        id: new Date().getTime(),
        name: "",
        avatar: "",
        message: message.trim(),
        attachments: [],
        attachImages: [],
        isFile: false,
        sender: selectedUser?.id,
        agent: "",
        contextId: null,
        isTemplate: true
      };

      sendMessage(newMessage)
      setOpenTemplates(false)
    }
  }

  return (
    <div className="absolute top-0 z-10 flex h-24 w-full items-center justify-between border-b border-border/40 bg-background/95 px-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex items-center gap-4">
        <CustomAvatar src={selectedUser?.attributes?.profile_photo?.[0]} alt={selectedUser?.attributes?.profile_photo?.[0]} icon={null} size={10} />
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium">
            {
              (selectedUser?.firstName && !selectedUser?.lastName) ? selectedUser?.firstName :
                (!selectedUser?.firstName && selectedUser?.lastName) ? selectedUser?.lastName :
                  (!selectedUser?.firstName && !selectedUser?.lastName) ? selectedUser?.phone
                    : selectedUser?.firstName + " " + selectedUser?.lastName}
          </span>
          <span className="text-xs text-muted-foreground">Active</span>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button title="Templates" size="icon" variant="ghost">
          <CgTemplate
            onClick={() => setOpenTemplates(true)}
            size={20}
            className="text-muted-foreground"
          />
        </Button>

        <Button title="Transfer" size="icon" disabled={(role === "supervisor" ? false : !isMyConversation) || conversationId?.length <= 0} variant="ghost">
          <BiTransferAlt
            onClick={() => setOpenTransfer(true)}
            size={24}
            className="text-muted-foreground"
          />
        </Button>

        <Button title="Close Conversation" disabled={(role === "supervisor" ? false : !isMyConversation) || conversationId?.length <= 0} size="icon" variant="ghost">
          <BiMessageX
            onClick={() => setOpenDelete(true)}
            size={20}
            className="text-muted-foreground"
          />
        </Button>

        <Button onClick={sidebar?.setIsOpen} size="icon" variant="ghost">
          <BsInfoCircleFill size={20} className="text-muted-foreground" />
        </Button>
      </div>
      {/* Delete Dialog */}
      <DynamicDialog
        open={openDelete}
        close={closeDelete}
        label={`Close conversation # ${selectedUser?.firstName + " " + selectedUser?.lastName}`}
        description=""
        content=<>
          <h2>Are you sure you want to close this conversation?</h2>
          <Button
            size="lg"
            variant="outline"
            className="flex w-full flex-row gap-1 border-red-400 hover:bg-red-400/20"
            onClick={handleCloseMesage}
          >
            <h3>{isClosingMessage ? <Loader2 className="w-4 h-4 animate-spin" /> : "Confirm"}</h3>
          </Button>
        </>
        handleSubmit={null}
      />
      <DynamicDialog
        open={openTemplates}
        close={closeTemplates}
        label="Send Template"
        description={`to ${selectedUser?.firstName + " " + selectedUser?.lastName}`}
        content={
          <TemplateList
            templates={templates}
            onSend={handleSend}
          />
        }
        handleSubmit={null}
      />

      <DynamicDialog
        open={openTransfer}
        close={closeTransfer}
        label="Transfer Conversation"
        description={`of ${selectedUser?.firstName}`}
        content={
          <TransferList
            userOptions={userOptions}
            handleSearchUser={handleSearchUser}
            userSearchText={userSearchText}
            onSend={handleTransferMesage}
          />
        }
        handleSubmit={null}
      />
    </div>
  )
}
