"use client"

import { useEffect, useState } from "react"
import { GetBusinessOwenerByManager } from "@/api_store/queries/user/useBusinessesByManager"
import { useGetUsers } from "@/api_store/queries/user/useUser"
import { GetUsersByLength } from "@/api_store/queries/user/userQuery"
import { getToken } from "@/auth/config/keycloak"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { buildFSStr } from "@/utils/filter-sort-param"

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import AutocompleteDropdown from "@/components/custom/autocomplete-dropdown"

import { Button } from "../ui/button"
import { getUsersByVendorId } from "@/api_store/queries/tradinStatements/getUsersByVendorId"
import SearchableDropdownNew from "./searchable-dropdown-new"
import { RefreshCcw } from "lucide-react"
import { GetUsersBySync } from "@/api_store/queries/user/userSync"

export function ConversationAddDialogNew({
  open,
  close,
  selectedUser,
  setSelectedUser,
  selectedBusiness,
  handleChangeBusiness,
  handleSubmit,
  setUserDataInfo,
  setSelectedUserPhone
}) {
  const [userOptions, setUserOptions] = useState([])
  const [userSearchText, setUserSearchText] = useState("")
  const [fsStr, setFsStr] = useState("")

  const [businessOptions, setBusinessOptions] = useState([])
  const [businessSearchText, setBusinessSearchText] = useState("")
  const [businessFsStr, setBusinessFsStr] = useState("")
  const [businessIds, setBusinessIds] = useState([])
  const [businessUid, setBusinessUid] = useState(0);
  const [syncUser, setSyncUser] = useState(false);

  const { user, tradeToken } = useKeycloak()

  const length = 10
  const page = 1

  const { data: userSyncData, isLoading: isLoadingUsers } = GetUsersBySync({ syncUser });

  // hooks
  const token = getToken();

  const {
    data: businessData,
    isLoading: isLoadingBusinesses,
    refetch: refetchBusinesses,
  } = GetBusinessOwenerByManager({ managerId: user?.userId, token })

  useEffect(() => {
    if (businessData) {
      setBusinessIds(businessData)
    }
  }, [businessData])

  const businesses = useGetUsers(businessIds)

  // const {
  //   data: userData,
  //   isLoading,
  //   error,
  //   refetch: refetchUsers,
  // } = GetUsersByLength({ length, page, token, fsStr })

  const {
    data: userData,
    isLoading,
    error,
    refetch: refetchUsers,
  } = getUsersByVendorId(page, length, userSearchText, businessUid, tradeToken);

  const transformUserDataForOptions = (data) => {
    return data?.map((user) => ({
      id: user.id,
      name: user.name,
      uid: user.uid,
      subtitle: user?.phone_number,
      avatarUrl: user?.profile_image,
    }))
  }


  const handleSearchUser = (value) => {
    setUserSearchText(value)

    // if (value.length <= 0) {
    //   setFsStr("")
    //   refetchUsers()
    //   return
    // }

    // const searchFilter = [
    //   [["firstName", "like", value]],
    //   [["lastName", "like", value]],
    //   [["uid", "eq", value]],
    //   [["phone", "eq", encodeURIComponent(value)]],
    // ]

    // const dt = buildFSStr({ filters: searchFilter, sort: null })
    // setFsStr(dt)
  }

  useEffect(() => {
    if (userData) {
      // setUserDataInfo(userData?.data?.result);
      const transformedData = transformUserDataForOptions(userData?.data?.result)
      setUserOptions(transformedData)
    }
  }, [userData])

  useEffect(() => {
    if (businesses) {
      const formatData = businesses.map((business, index) => ({
        value: business?.data?.id,
        label: business?.data?.firstName + " " + business?.data?.lastName,
        uid: index + 1
      }))

      setBusinessOptions(formatData)
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      setSelectedUser(null)
      handleChangeBusiness(null)
      setUserSearchText("")
      setBusinessSearchText("")
    }
  }, [open])

  const handleSearchBusiness = (value) => {
    setBusinessSearchText(value)

    if (value.length <= 0) {
      setBusinessFsStr("")
      return
    }

    const searchFilter = [
      [["firstName", "like", value]],
      [["lastName", "like", value]],
      [["phone", "eq", encodeURIComponent(value)]],
    ]
    const dt = buildFSStr({ filters: searchFilter, sort: null })
    setBusinessFsStr(dt)
  }

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center">
            New Message
            <Button
              onClick={() => setSyncUser(!syncUser)}
              variant="secondary"
              size="icon"
              className="ml-2 bg-transparent"
            >
              <RefreshCcw className={isLoadingUsers ? "animate-spin h-4 w-4" : "h-4 w-4"} />
            </Button>
          </DialogTitle>
        </DialogHeader>
        <div className="grid items-center gap-4 py-4">
          <div className="mt-4 grid grid-cols-4 items-center gap-4">
            <Label htmlFor="business" className="text-left leading-6">
              Business:
            </Label>
            <AutocompleteDropdown
              options={businessOptions}
              value={selectedBusiness}
              onChange={(value) => {
                handleChangeBusiness(value)
                const uid = businessOptions.find((option) => option.value === value)?.uid
                setBusinessUid(uid);
              }}
              placeholder="Search Business..."
              dropdownLabel="Select a Business..."
              searchText={businessSearchText}
              setSearchText={handleSearchBusiness}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-4">
            <Label htmlFor="contact" className="text-left">
              Contact:
            </Label>
            <div className="col-span-3">
              <SearchableDropdownNew
                users={userOptions}
                searchQuery={userSearchText}
                setSearchQuery={setUserSearchText}
                onSelect={setSelectedUser}
                onSearch={handleSearchUser}
                selectedUserId={selectedUser}
                setSelectedUserPhone={setSelectedUserPhone}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="default" onClick={handleSubmit}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}